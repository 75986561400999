import { ExpandMore, ExpandLess, Close } from "@mui/icons-material";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  ListItemText,
  Checkbox,
  MenuItem,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { apiUpdateForm } from "../../../shared/components/Sliders/utils";
import { accessTypes } from "../../constants/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import LSInput, { inputVariants } from "../formInputs/LSInput";

const scrollContainerStyles = {
  height: "200px",
  overflow: "scroll",
  borderRadius: 2,
  marginTop: 2,
  border: 1,
  padding: 1,
};

function FormSettingsModalContent({
  form,
  users,
  closeModal,
  handleUpdateFormSettings,
  locations,
}) {
  const [status, setStatus] = useState({ status: "" });
  const [showSetting, setShowSetting] = useState(0);
  const [formSettings, setFormSettings] = useState({
    ...form,
    admins:
      form.formAdmins?.map((admin) => ({
        key: admin.userUUID,
        value: "",
        ...admin,
      })) || [],
  });

  const handleOpenSettings = (num) => {
    if (num === showSetting) {
      setShowSetting(0);
    } else {
      setShowSetting(num);
    }
  };

  const handleAddStatusClick = () => {
    const newStatus = {
      name: status.status,
      id: 0,
      formUUID: formSettings.uuid,
    };
    const tempApp = [...formSettings.statuses, newStatus];
    tempApp.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      return 1;
    });
    setFormSettings((prevState) => ({
      ...prevState,
      statuses: tempApp,
    }));
    setStatus({ status: "" });
  };

  const handleStatusChange = (_status, _default, _delete) => {
    if (_default) {
      setFormSettings((prevState) => ({
        ...prevState,
        defaultStatusId: _status.id,
      }));
    } else if (_delete) {
      const statusIdx = formSettings.statuses.findIndex(
        (x) => x.id === _status.id
      );
      const statuses = [...formSettings.statuses];
      statuses.splice(statusIdx, 1);
      setFormSettings((prevState) => ({
        ...prevState,
        defaultStatus: "",
        statuses,
      }));
    }
  };

  const handleChangeAccess = (type) => {
    setFormSettings((prevState) => ({
      ...prevState,
      accessType: type,
    }));
  };

  const handleChangeAdmins = (user, isViewOnly = false) => {
    const adminIdx = formSettings.admins.findIndex((x) => x.key === user.uuid);
    if (adminIdx > -1) {
      // remove the user
      const tempAdminsArr = formSettings.admins;
      tempAdminsArr.splice(adminIdx, 1);
      setFormSettings((prevState) => ({
        ...prevState,
        admins: tempAdminsArr,
      }));
    } else {
      setFormSettings((prevState) => ({
        ...prevState,
        admins: [
          ...prevState.admins,
          {
            key: user.uuid,
            value: `${user.firstName} ${user.lastName}`,
            isViewOnly,
          },
        ],
      }));
    }
  };

  const handleChangeLocations = (location) => {
    const locationIdx = formSettings.locationDtos.findIndex(
      (x) => x.uuid === location.uuid
    );

    if (locationIdx > -1) {
      // remove the location
      const tempLocationsArr = formSettings.locationDtos;
      tempLocationsArr.splice(locationIdx, 1);
      setFormSettings((prevState) => ({
        ...prevState,
        locationDtos: tempLocationsArr,
      }));
    } else {
      setFormSettings((prevState) => ({
        ...prevState,
        locationDtos: [
          ...prevState.locationDtos,
          {
            uuid: location.uuid,
            name: location.name,
          },
        ],
      }));
    }
  };

  const handleUpdateSettings = async () => {
    // const body = {
    //   uuid: form.uuid,
    //   formAdmins: formSettings.admins.map((admin) => ({
    //     formUUID: form.uuid,
    //     userUUID: admin.key,
    //   })),
    //   accessType: formSettings.accessType,
    // };
    // console.log({ formSettings, body });
    const response = await apiUpdateForm({
      ...formSettings,
      formAdmins: formSettings.admins.map((admin) => ({
        userUUID: admin.key,
        isViewOnly: admin.isViewOnly,
      })),
      admins: null,
      questions: [],
    });

    handleUpdateFormSettings(response);
    closeModal();
  };

  return (
    <Box sx={{ width: "100%", marginBottom: "0px" }}>
      <LSInput
        variant={inputVariants.primary}
        setter={setFormSettings}
        object={formSettings}
        name="name"
        placeholder="Form Name"
        customStyles={{ margin: 0, width: "280px" }}
      />
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: "lightGray",
          marginY: 0.5,
          paddingY: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, marginTop: "8px" }}
          >
            Admins
          </Typography>
          <IconButton onClick={() => handleOpenSettings(1)}>
            {showSetting === 1 ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={showSetting === 1}>
          <Box sx={{ height: "200px", overflow: "scroll" }}>
            {users.map((user) => {
              const adminIdx = formSettings.admins.findIndex(
                (x) => x.key === user.uuid
              );
              const disabled =
                adminIdx > -1 && formSettings.admins[adminIdx].isViewOnly;
              return (
                <MenuItem
                  key={user.uuid}
                  value={user}
                  sx={{ padding: 0 }}
                  onClick={() => handleChangeAdmins(user)}
                  disabled={disabled}
                >
                  <Checkbox checked={adminIdx > -1 && !disabled} />
                  <ListItemText
                    primary={`${user.firstName} ${user.lastName} ${
                      disabled ? "- View Admin" : ""
                    }`}
                  />
                </MenuItem>
              );
            })}
          </Box>
        </Collapse>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: "lightGray",
          marginY: 0.5,
          paddingY: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, marginTop: "8px" }}
          >
            View Admins
          </Typography>
          <IconButton onClick={() => handleOpenSettings(2)}>
            {showSetting === 1 ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={showSetting === 2}>
          <Box sx={{ height: "200px", overflow: "scroll" }}>
            {users.map((user) => {
              const adminIdx = formSettings.admins.findIndex(
                (x) => x.key === user.uuid
              );
              const disabled =
                adminIdx > -1 && !formSettings.admins[adminIdx].isViewOnly;
              return (
                <MenuItem
                  key={user.uuid}
                  value={user}
                  sx={{ padding: 0 }}
                  onClick={() => handleChangeAdmins(user, true)}
                  disabled={disabled}
                >
                  <Checkbox checked={adminIdx > -1 && !disabled} />
                  <ListItemText
                    primary={`${user.firstName} ${user.lastName} ${
                      disabled ? "- Admin" : ""
                    }`}
                  />
                </MenuItem>
              );
            })}
          </Box>
        </Collapse>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: "lightGray",
          marginY: 0.5,
          paddingY: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, marginTop: "8px" }}
          >
            Statuses
          </Typography>
          <IconButton onClick={() => handleOpenSettings(3)}>
            {showSetting === 2 ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={showSetting === 3}>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
            <LSInput
              variant={inputVariants.primary}
              setter={setStatus}
              object={status}
              name="status"
              placeholder="New Status"
              customStyles={{ margin: 0, width: "207px" }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ height: "38px", marginLeft: 1 }}
              onClick={handleAddStatusClick}
            >
              Add
            </Button>
          </Box>
          <Box sx={scrollContainerStyles}>
            {formSettings.statuses.map((_status, idx) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${_status.uuid}-${idx}`}
                value={_status}
                sx={{ padding: 0 }}
              >
                <Checkbox
                  checked={_status.id === formSettings.defaultStatusId}
                  onClick={() => handleStatusChange(_status, true)}
                />
                <ListItemText primary={_status.name} />
                <IconButton
                  onClick={() => handleStatusChange(_status, null, true)}
                  sx={{ marginTop: "-2px" }}
                >
                  <Close />
                </IconButton>
              </MenuItem>
            ))}
          </Box>
        </Collapse>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: "lightGray",
          marginY: 0.5,
          paddingY: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, marginTop: "8px" }}
          >
            Access
          </Typography>
          <IconButton onClick={() => handleOpenSettings(4)}>
            {showSetting === 2 ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={showSetting === 4}>
          <Box sx={{ height: "200px", overflow: "scroll" }}>
            {accessTypes.map((accessType) => (
              <MenuItem
                key={accessType.key}
                value={accessType}
                sx={{ padding: 0 }}
                onClick={() => handleChangeAccess(accessType.key)}
              >
                <Checkbox
                  checked={formSettings.accessType === accessType.key}
                />
                <ListItemText primary={`${accessType.value}`} />
              </MenuItem>
            ))}
          </Box>
        </Collapse>
      </Box>
      <Collapse in={formSettings.accessType === 3}>
        <Box
          sx={{
            borderBottom: 1,
            borderBottomColor: "lightGray",
            marginY: 0.5,
            paddingY: 0.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, marginTop: "8px" }}
            >
              Locations
            </Typography>
            <IconButton onClick={() => handleOpenSettings(5)}>
              {showSetting === 2 ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={showSetting === 5}>
            <Box sx={{ height: "200px", overflow: "scroll" }}>
              {locations.map((location) => {
                const locationIdx = formSettings.locationDtos.findIndex(
                  (x) => x.uuid === location.uuid
                );
                return (
                  <MenuItem
                    key={location.uuid}
                    value={location}
                    sx={{ padding: 0 }}
                    onClick={() => handleChangeLocations(location)}
                  >
                    <Checkbox checked={locationIdx > -1} />
                    <ListItemText primary={location.name} />
                  </MenuItem>
                );
              })}
            </Box>
          </Collapse>
        </Box>
      </Collapse>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <LSButton
          text="Cancel"
          variant={buttonVariants.secondary}
          customStyles={{}}
          onPress={closeModal}
        />
        <LSButton
          text="Save Changes"
          variant={buttonVariants.primary}
          customStyles={{ flex: 1, marginHorizontal: 12 }}
          onPress={handleUpdateSettings}
        />
      </Box>
    </Box>
  );
}

FormSettingsModalContent.propTypes = {
  form: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleUpdateFormSettings: PropTypes.func.isRequired,
};

export default FormSettingsModalContent;
