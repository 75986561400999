export const responseCodes = {
  OK: 200,
  Created: 201,
};

export const accessTypes = [
  { key: 1, value: "Public", desc: "Anyone with a link can submit a report" },
  { key: 2, value: "Internal", desc: "Only company users can submit a report" },
  {
    key: 3,
    value: "Location Based",
    desc: "Only users assigned to specified locations can submit a report",
  },
  {
    key: 4,
    value: "Task Based",
    desc: "Only users with a task to submit can submit a report",
  },
];
