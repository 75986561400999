/* eslint-disable no-nested-ternary */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Collapse,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import LSModal from "./LSModal";
import { BASE_URL } from "../../../shared/apiUrls";
import {
  completionTimelineOptions,
  promptOptions,
  taskTypes,
} from "../../hooks/constants";
import useAddActionItems from "../../hooks/useAddActionItems";
import useForms from "../../hooks/useForms";
import useUsers from "../../hooks/useUsers";
import { urlRoutes } from "../../pages/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSDropdownPicker from "../formInputs/LSDropdownPicker";
import LSInput from "../formInputs/LSInput";
import LSText, { textVariant } from "../text/LSText";

const headerWidth = "65px";

export default function TaskViewEditModal({
  addModalVisible,
  setAddModalVisible,
  formSubmit,
  handleNewTaskAdd,
  tasks,
  selectedTask,
  handleRemoveDeletedTask,
  form,
  admin,
}) {
  const handleClose = () => {
    setView(true);
    setConfirm(false);
    setAddModalVisible(false);
  };

  const {
    task,
    confirm,
    taskErrors,
    descMaxChar,
    confirmDelete,
    setConfirm,
    setTask,
    handleSave,
    handleDelete,
    setConfirmDelete,
  } = useAddActionItems(
    formSubmit?.uuid,
    handleNewTaskAdd,
    tasks,
    handleClose,
    selectedTask,
    handleRemoveDeletedTask,
    form?.uuid
  );
  const { users } = useUsers();
  const { forms } = useForms();
  const [view, setView] = useState(true);

  const mappedUsers = users
    ?.sort((a, b) => a.lastName - b.lastName)
    .map((user) => ({
      key: user.uuid,
      value: `${user.lastName}, ${user.firstName}`,
    }));

  const mappedForms = forms
    ?.sort((a, b) => a.lastName - b.lastName)
    .map((_form) => ({
      key: _form.uuid,
      value: _form.name,
    }));

  return (
    <LSModal visible={addModalVisible} setVisible={handleClose}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderBottom: 2,
              marginX: -2,
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
            }}
          >
            <Box sx={{ width: "50px" }} />
            <LSText
              text={
                confirm || confirmDelete
                  ? "Are You Sure?"
                  : view
                  ? "Task Details"
                  : "Edit Task"
              }
              variant={textVariant.h2}
              customStyles={{ flex: 1, marginBottom: 16, textAlign: "center" }}
            />
            <Box sx={{ width: "50px", marginTop: "-4px" }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            {confirm || confirmDelete ? (
              <LSText
                text={`Are you sure you want to ${
                  confirm ? "edit" : "delete"
                } this task?`}
                variant={textVariant.h6}
                customStyles={{ marginBottom: 8 }}
              />
            ) : view ? (
              <Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ width: headerWidth, marginTop: "-3px" }}>
                    <LSText
                      text="User:"
                      variant={textVariant.H5}
                      customStyles={{ fontWeight: "bold", marginRight: "8px" }}
                    />
                  </Box>
                  <LSText text={task.userName} variant={textVariant.H5} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ width: headerWidth, marginTop: "-3px" }}>
                    <LSText
                      text="Title:"
                      variant={textVariant.H5}
                      customStyles={{ fontWeight: "bold", marginRight: "8px" }}
                    />
                  </Box>
                  <LSText
                    text={task.title || task.formName}
                    variant={textVariant.H5}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ width: headerWidth, marginTop: "-3px" }}>
                    <LSText
                      text="Desc.:"
                      variant={textVariant.H5}
                      customStyles={{ fontWeight: "bold", marginRight: "8px" }}
                    />
                  </Box>
                  <LSText
                    text={task.description}
                    customStyles={{ maxWidth: "210px" }}
                    variant={textVariant.H5}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ width: headerWidth, marginTop: "-3px" }}>
                    <LSText
                      text="Status:"
                      variant={textVariant.H5}
                      customStyles={{ fontWeight: "bold", marginRight: "8px" }}
                    />
                  </Box>
                  <LSText text={task.status} variant={textVariant.H5} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ width: headerWidth, marginTop: "-3px" }}>
                    <LSText
                      text="Date:"
                      variant={textVariant.H5}
                      customStyles={{ fontWeight: "bold", marginRight: "8px" }}
                    />
                  </Box>
                  <LSText
                    text={
                      task.completedAt
                        ? new Date(
                            `${new Date(task.completedAt).toLocaleString()} UTC`
                          ).toLocaleString()
                        : "Not Complete"
                    }
                    variant={textVariant.H5}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ marginTop: "16px" }}>
                    <LSText
                      text="Attachments:"
                      variant={textVariant.H5}
                      customStyles={{ fontWeight: "bold", marginRight: "8px" }}
                    />
                  </Box>
                  {task?.formName && task?.taskFormSubmissionUUID && (
                    <LSText
                      text={task.formName}
                      path={`${urlRoutes.submittedFormView}?report=${task.taskFormSubmissionUUID}`}
                      variant={textVariant.H5}
                      callback={() => setAddModalVisible(false)}
                      customLinkStyle={{
                        marginLeft: 0,
                        textDecoration: "none",
                      }}
                    />
                  )}
                  {task?.savedFiles?.map((file) => (
                    <Box key={file.filePath}>
                      <a
                        href={`${BASE_URL}${file.filePath}`}
                        target="_blank"
                        download
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {file.fileName}
                      </a>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <>
                <LSText
                  text="User to complete task"
                  variant={textVariant.overline}
                  customStyles={{ marginBottom: 8 }}
                />
                <LSDropdownPicker
                  _items={mappedUsers}
                  name="userUUID"
                  answerKey="userUUID"
                  answer={task}
                  setValue={setTask}
                  error={taskErrors.userUUID}
                />
                <LSText
                  text="Email Prompt"
                  variant={textVariant.overline}
                  customStyles={{ marginBottom: 8, marginTop: 16 }}
                />
                <LSDropdownPicker
                  _items={promptOptions}
                  name="prompt"
                  answerKey="prompt"
                  answer={task}
                  setValue={setTask}
                  error={taskErrors.prompt}
                />
                <LSText
                  text="Task Type"
                  variant={textVariant.overline}
                  customStyles={{ marginBottom: 8, marginTop: 16 }}
                />
                <LSDropdownPicker
                  _items={taskTypes}
                  name="type"
                  answerKey="type"
                  answer={task}
                  setValue={setTask}
                  error={taskErrors.type}
                />
                <Collapse
                  sx={{ marginTop: task.type === 1 ? 2 : 0 }}
                  in={task.type === 1}
                >
                  <LSText
                    text="Form to be completed"
                    variant={textVariant.overline}
                    customStyles={{ marginBottom: "8px" }}
                  />
                  <LSDropdownPicker
                    _items={mappedForms}
                    name="taskFormUUID"
                    answerKey="taskFormUUID"
                    answer={task}
                    setValue={setTask}
                    error={taskErrors.taskFormUUID}
                  />
                </Collapse>
                <Collapse in={task.type === 2 || task.type === 3}>
                  <LSText
                    text="Title"
                    variant={textVariant.overline}
                    customStyles={{ marginTop: 16 }}
                  />
                  <LSInput
                    setter={setTask}
                    name="title"
                    object={task}
                    customStyles={{ backgroundColor: "white" }}
                    errorObj={taskErrors}
                  />
                </Collapse>
                <LSText
                  text="Completion Timeline"
                  variant={textVariant.overline}
                  customStyles={{ marginBottom: 8, marginTop: 16 }}
                />
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  row
                  value={task.completionTime}
                  onChange={(e) =>
                    setTask((prevState) => ({
                      ...prevState,
                      completionTime: e.target.value,
                    }))
                  }
                >
                  {completionTimelineOptions.map((option) => (
                    <FormControlLabel
                      key={`${option.value}-${option.key}`}
                      value={option.key}
                      control={<Radio />}
                      label={option.value}
                      componentsProps={{ typography: { variant: "subtitle2" } }}
                    />
                  ))}
                </RadioGroup>
                <LSText
                  text="Description"
                  variant={textVariant.overline}
                  customStyles={{ marginTop: 8 }}
                />
                <LSInput
                  multiline
                  numberOfLines={5}
                  setter={setTask}
                  name="description"
                  object={task}
                  customStyles={{ backgroundColor: "white" }}
                  errorObj={taskErrors}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="caption">
                    {task.description.length} / {descMaxChar}
                  </Typography>
                </Box>
              </>
            )}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {(confirm || confirmDelete) && (
                <LSButton
                  onPress={() => {
                    setConfirmDelete(false);
                    setConfirm(false);
                  }}
                  text="Cancel"
                  variant={buttonVariants.secondary}
                  customStyles={{ marginRight: 16, flex: 1 }}
                />
              )}
              {!view && !confirm && (
                <LSButton
                  onPress={handleDelete}
                  text="Delete"
                  variant={buttonVariants.error}
                  customStyles={{ marginRight: 16, flex: 1 }}
                />
              )}
              {!confirmDelete && admin && !admin.isViewOnly && (
                <LSButton
                  onPress={() => {
                    if (view) {
                      setView(false);
                    } else {
                      handleSave();
                    }
                  }}
                  variant={buttonVariants.primary}
                  text={confirm ? "Confirm" : view ? "Edit" : "Save"}
                  customStyles={{ flex: 1 }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </LSModal>
  );
}

TaskViewEditModal.propTypes = {
  addModalVisible: PropTypes.bool.isRequired,
  setAddModalVisible: PropTypes.func.isRequired,
  handleNewTaskAdd: PropTypes.func.isRequired,
  handleRemoveDeletedTask: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  selectedTask: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired,
  formSubmit: PropTypes.object,
  form: PropTypes.object,
};

TaskViewEditModal.defaultProps = {
  formSubmit: null,
  form: null,
};
