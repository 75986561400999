import Person2Icon from "@mui/icons-material/Person2";
import { Box, Paper } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { fontColorDark } from "../../../theme/colors";
import LSText, { textVariant } from "../text/LSText";

export default function UserCard({ user, handleShowUserProfile }) {
  const { uuid, firstName, lastName, position, active } = user;
  const inactiveText = active === null ? "Pending Invitation" : "Inactive";
  return (
    <Paper
      key={`user-${uuid}`}
      elevation={4}
      sx={{ width: "250px", padding: 2, margin: 1 }}
      onClick={() => handleShowUserProfile(user)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ marginBottom: 2 }}>
          <Box
            sx={{
              backgroundColor: "lightGray",
              width: "100px",
              height: "100px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <Person2Icon sx={{ fontSize: "80px" }} />
          </Box>
        </Box>
        <LSText
          variant={textVariant.h4}
          text={`${lastName}, ${firstName}`}
          color={fontColorDark}
          customStyles={{ marginBottom: 8 }}
        />
        <LSText
          variant={textVariant.text}
          text={`${active ? "Active" : inactiveText}`}
          color={fontColorDark}
          customStyles={{ marginBottom: 8 }}
        />
        <LSText
          variant={textVariant.h4}
          text={position}
          color={fontColorDark}
          customStyles={{ marginBottom: 8 }}
        />
      </Box>
    </Paper>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  handleShowUserProfile: PropTypes.func.isRequired,
};
