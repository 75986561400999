import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import LSModal from "./LSModal";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSText, { textVariant } from "../text/LSText";

export default function DeleteFileModal({
  modalVisible,
  setModalVisible,
  confirmDelete,
  fileName,
}) {
  return (
    <LSModal visible={modalVisible} setVisible={setModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderBottom: 2,
              marginX: -2,
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <LSText
              text="Are You Sure?"
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16, textAlign: "center" }}
            />
          </Box>
          <LSText
            text={`Confirm you want to delete the file ${fileName}`}
            variant={textVariant.text}
            customStyles={{ marginBottom: 16, textAlign: "center" }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LSButton
                onPress={() => setModalVisible(false)}
                text="Cancel"
                variant={buttonVariants.secondary}
                customStyles={{ marginRight: 16, flex: 1 }}
              />
              <LSButton
                onPress={confirmDelete}
                text="Confirm"
                variant={buttonVariants.error}
                customStyles={{ flex: 1 }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </LSModal>
  );
}

DeleteFileModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
};

DeleteFileModal.defaultProps = {};
