import { useState, useEffect } from "react";

import { GET_USERS, UPDATE_USER_URL } from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPatchAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";

function useUsers(locations) {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState();
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    getUsers();
  }, [editUser?.isActive]);

  const getUsers = async () => {
    const results = await apiGetAuth(GET_USERS);
    setUsers(results);
    setLoading(false);
  };

  const handleEditUserLocation = (locationId) => {
    const existingLocationIdxIdx = editUser.locations.findIndex(
      (location) => location.uuid === locationId
    );

    if (existingLocationIdxIdx >= 0) {
      const tempUserLocationsArr = [...editUser.locations];
      tempUserLocationsArr.splice(existingLocationIdxIdx, 1);

      setEditUser((prevState) => ({
        ...prevState,
        locations: tempUserLocationsArr,
      }));
    } else {
      const locationIdx = locations.findIndex(
        (location) => location.uuid === locationId
      );
      setEditUser((prevState) => ({
        ...prevState,
        locations: [...prevState.locations, locations[locationIdx]],
      }));
    }
  };

  const handleSaveEditUserChanges = async () => {
    await apiPatchAuth(UPDATE_USER_URL, { ...editUser, position: null });
    getUsers();
  };

  const handleDeactivateUser = () => {
    console.log({ editUser });
  };

  return {
    loading,
    users,
    editUser,
    handleEditUserLocation,
    handleSaveEditUserChanges,
    handleDeactivateUser,
    setEditUser,
    setUsers,
  };
}

export default useUsers;
