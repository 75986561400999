import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  GET_USER_PERMISSIONS,
  UPDATE_USER_PERMISSIONS,
} from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPatchAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { defaultUserPermissions } from "../pages/constants";
import { setPermissions } from "../redux/permissions";

export default function useUserPermissions(user) {
  const permissions = useSelector((state) => state.permissions);
  const dispatch = useDispatch();

  const [userPermissions, setUserPermissions] = useState(
    defaultUserPermissions
  );

  const handleGetUserPermissions = async () => {
    if (user?.uuid) {
      const response = await apiGetAuth(`${GET_USER_PERMISSIONS}/${user.uuid}`);
      setUserPermissions(response);
    }
  };

  useEffect(() => {
    handleGetUserPermissions();
  }, [user]);

  const handleUpdatePermissions = (permissionName) => {
    setUserPermissions((prevState) => ({
      ...prevState,
      [permissionName]:
        prevState[permissionName] === null ? true : !prevState[permissionName],
    }));
  };

  const handleSave = async (_userPermissions) => {
    const response = await apiPatchAuth(
      `${UPDATE_USER_PERMISSIONS}`,
      _userPermissions
    );

    if (permissions.userUUID === response.userUUID) {
      dispatch(setPermissions(response));
    }
  };

  return { userPermissions, handleUpdatePermissions, handleSave };
}
