import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { Box, Paper } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { BASE_URL } from "../../../shared/apiUrls";
import { fontColorDark } from "../../../theme/colors";
import LSText, { textVariant } from "../text/LSText";

export default function LocationCard({ location, handleLocationClick }) {
  const { name, address, city, state, zip, uuid, profileImagePath } = location;
  return (
    <Paper
      key={uuid}
      elevation={4}
      sx={{ width: "250px", padding: 2, margin: 1 }}
      onClick={() => handleLocationClick(location)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginBottom: 3,
            height: "150px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: profileImagePath ? "auto" : "lightGray",
          }}
        >
          {profileImagePath ? (
            <img
              style={{ width: "100%" }}
              src={`${BASE_URL}${profileImagePath}`}
              alt=""
            />
          ) : (
            <HomeWorkIcon sx={{ fontSize: "150px" }} />
          )}
        </Box>
        <LSText
          variant={textVariant.h4}
          text={name}
          color={fontColorDark}
          customStyles={{ marginBottom: 8 }}
        />
        <LSText
          variant={textVariant.text}
          text={address}
          color={fontColorDark}
        />
        <LSText
          variant={textVariant.text}
          text={`${city}, ${state} ${zip}`}
          color={fontColorDark}
        />
      </Box>
    </Paper>
  );
}

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
  handleLocationClick: PropTypes.func.isRequired,
};
