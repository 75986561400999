/* eslint-disable import/no-extraneous-dependencies */
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckMarks({
  id,
  options,
  label,
  handleChangeAnswer,
  formData,
  fieldName,
  errors,
}) {
  const [selectedList, setSelectedList] = useState(formData[fieldName] || []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedList(value);
  };

  useEffect(() => {
    handleChangeAnswer(selectedList);
  }, [selectedList]);

  useEffect(() => {
    setSelectedList(formData[fieldName] || []);
  }, [id]);

  return (
    <div>
      <FormControl sx={{ width: "100%", zIndex: 101 }} error={errors}>
        <InputLabel id={`multiple-checkbox-label-${id}`}>{label}</InputLabel>
        <Select
          labelId={`multiple-checkbox-label-${id}`}
          id={`multiple-checkbox-${id}`}
          multiple
          value={selectedList}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            let selectedString = "";
            selected.forEach((option) => {
              if (option.value) {
                selectedString += `${option.value}, `;
              }
            });
            return selectedString;
          }}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.key} value={option}>
              <Checkbox
                checked={
                  selectedList.findIndex((x) => x.key === option.key) > -1
                }
              />
              <ListItemText primary={option.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

MultipleSelectCheckMarks.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleChangeAnswer: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  errors: PropTypes.bool,
};

MultipleSelectCheckMarks.defaultProps = {
  label: "Select",
  errors: false,
};
