import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  apiGetForms,
  apiGenerateNewForm,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { urlRoutes } from "../pages/constants";

const emptyFormValue = {
  name: "",
  type: null,
  accessType: null,
  admins: [],
  viewAdmins: [],
  locations: [],
  statuses: ["Closed", "Complete", "Open", "Pending"],
  defaultStatus: "Open",
};

const formErrors = {
  name: false,
  type: false,
  admins: false,
  viewAdmins: false,
  statuses: false,
  defaultStatus: false,
  accessType: false,
};

export default function useFormsPage() {
  const { userUUID } = useSelector((state) => state.permissions);
  const [newForm, setNewForm] = useState({
    ...emptyFormValue,
    admins: [{ key: userUUID }],
  });
  const [newFormErrors, setNewFormErrors] = useState({ ...formErrors });
  const [form, setForm] = useState({});
  const [addModalVisible, setAddModalVisible] = useState(0);
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();

  const getForms = async () => {
    setLoading(true);
    const response = await apiGetForms();
    setForms(response);
    setLoading(false);
  };

  const handleOnPressPublish = (idx) => {
    const tempForms = [...forms];
    tempForms[idx].isPublished = !tempForms[idx].isPublished;
    setForms(tempForms);
  };

  const handleOnLockPublish = (idx) => {
    const tempForms = [...forms];
    tempForms[idx].isLocked = !tempForms[idx].isLocked;
    setForms(tempForms);
  };

  const handlePressBack = () => {
    setAddModalVisible((prevState) => prevState - 1);
    if (addModalVisible === 7 && newForm.accessType !== 3) {
      setAddModalVisible((prevState) => prevState - 1);
    }
  };

  const handleStepValidation = () => {
    let errors = false;
    if (addModalVisible === 1) {
      if (!newForm.name || newForm.name.length > 100) {
        errors = true;
        setNewFormErrors((prevState) => ({
          ...prevState,
          name: true,
        }));
      } else {
        setNewFormErrors((prevState) => ({
          ...prevState,
          name: false,
        }));
      }
      if (!newForm.type) {
        errors = true;
        setNewFormErrors((prevState) => ({
          ...prevState,
          type: true,
        }));
      } else {
        setNewFormErrors((prevState) => ({
          ...prevState,
          type: false,
        }));
      }
    } else if (addModalVisible === 2) {
      if (!newForm.admins?.length > 0) {
        errors = true;
        setNewFormErrors((prevState) => ({
          ...prevState,
          admins: true,
        }));
      } else {
        setNewFormErrors((prevState) => ({
          ...prevState,
          admins: false,
        }));
      }
    } else if (addModalVisible === 4) {
      if (!newForm.statuses?.length > 0 || !newForm.defaultStatus) {
        errors = true;
        setNewFormErrors((prevState) => ({
          ...prevState,
          statuses: true,
        }));
      } else {
        setNewFormErrors((prevState) => ({
          ...prevState,
          statuses: false,
        }));
      }
    } else if (addModalVisible === 5) {
      if (!newForm.accessType) {
        errors = true;
        setNewFormErrors((prevState) => ({
          ...prevState,
          accessType: true,
        }));
      } else {
        setNewFormErrors((prevState) => ({
          ...prevState,
          accessType: false,
        }));
      }
    } else if (addModalVisible === 6) {
      if (!newForm.locations?.length > 0) {
        errors = true;
        setNewFormErrors((prevState) => ({
          ...prevState,
          locations: true,
        }));
      } else {
        setNewFormErrors((prevState) => ({
          ...prevState,
          locations: false,
        }));
      }
    }

    return errors;
  };

  const handlePressNext = async () => {
    const errors = handleStepValidation();
    if (!errors) {
      if (addModalVisible === 7) {
        const newFormDto = {
          name: newForm.name,
          type: newForm.type,
          accessType: newForm.accessType,
          defaultStatus: newForm.defaultStatus,
          statuses: newForm.statuses,
          locations: newForm.locations.map((location) => location.key),
          admins: newForm.admins.map((location) => location.key),
          viewAdmins: newForm.viewAdmins.map((location) => location.key),
        };

        const response = await apiGenerateNewForm(newFormDto);

        if (response.uuid) {
          setAddModalVisible(0);
          navigate(
            `${urlRoutes.incidentsReportsMapping}?form=${response.uuid}`
          );
        }
      } else {
        setAddModalVisible((prevState) => prevState + 1);
        if (addModalVisible === 5 && newForm.accessType !== 3) {
          setAddModalVisible((prevState) => prevState + 1);
        }
      }
    }
  };

  const handleAddNewForm = () => {
    setAddModalVisible(1);
  };

  const handleChangeAdmins = (user) => {
    const adminIdx = newForm.admins.findIndex((x) => x.key === user.uuid);
    if (adminIdx > -1) {
      // remove the user
      const tempAdminsArr = newForm.admins;
      tempAdminsArr.splice(adminIdx, 1);
      setNewForm((prevState) => ({
        ...prevState,
        admins: tempAdminsArr,
      }));
    } else {
      setNewForm((prevState) => ({
        ...prevState,
        admins: [
          ...prevState.admins,
          {
            key: user.uuid,
            value: `${user.firstName} ${user.lastName}`,
          },
        ],
      }));
    }
  };

  const handleChangeViewAdmins = (user) => {
    const adminIdx = newForm.viewAdmins.findIndex((x) => x.key === user.uuid);
    if (adminIdx > -1) {
      // remove the user
      const tempAdminsArr = newForm.viewAdmins;
      tempAdminsArr.splice(adminIdx, 1);
      setNewForm((prevState) => ({
        ...prevState,
        viewAdmins: tempAdminsArr,
      }));
    } else {
      setNewForm((prevState) => ({
        ...prevState,
        viewAdmins: [
          ...prevState.viewAdmins,
          {
            key: user.uuid,
            value: `${user.firstName} ${user.lastName}`,
          },
        ],
      }));
    }
  };

  const handleStatusChange = (status, _default, _delete) => {
    if (_default) {
      setNewForm((prevState) => ({
        ...prevState,
        defaultStatus: status,
      }));
    } else if (_delete) {
      const statusIdx = newForm.statuses.indexOf(status);
      const statuses = [...newForm.statuses];
      statuses.splice(statusIdx, 1);
      setNewForm((prevState) => ({
        ...prevState,
        defaultStatus: "",
        statuses,
      }));
    } else {
      const statusIdx = newForm.statuses.indexOf(status);
      if (statusIdx < 0) {
        const statuses = [...newForm.statuses, status].sort();
        setNewForm((prevState) => ({
          ...prevState,
          statuses,
        }));
      }
    }
  };

  const handleChangeLocations = (location) => {
    const locationIdx = newForm.locations.findIndex(
      (x) => x.key === location.uuid
    );
    if (locationIdx > -1) {
      const tempAdminsArr = newForm.locations;
      tempAdminsArr.splice(locationIdx, 1);
      setNewForm((prevState) => ({
        ...prevState,
        locations: tempAdminsArr,
      }));
    } else {
      setNewForm((prevState) => ({
        ...prevState,
        locations: [
          ...prevState.locations,
          {
            key: location.uuid,
            value: location.name,
          },
        ],
      }));
    }
  };

  const handleChangeAccess = (type) => {
    setNewForm((prevState) => ({
      ...prevState,
      accessType: type,
    }));
  };

  const handleUpdateFormSettings = (changedForm) => {
    const idx = forms.findIndex((_form) => _form.uuid === changedForm.uuid);
    const tempForms = [...forms];
    tempForms[idx] = changedForm;
    setForms(tempForms);
  };

  const handleOnEditPress = (_form) => {
    setForm(_form);
    setAddModalVisible(11);
  };

  useEffect(() => {
    getForms();
  }, []);

  return {
    form,
    forms,
    loading,
    newForm,
    newFormErrors,
    addModalVisible,
    setNewForm,
    handlePressNext,
    handlePressBack,
    handleAddNewForm,
    handleOnEditPress,
    handleChangeAccess,
    handleStatusChange,
    handleChangeAdmins,
    setAddModalVisible,
    handleOnLockPublish,
    handleOnPressPublish,
    handleChangeLocations,
    handleChangeViewAdmins,
    handleUpdateFormSettings,
  };
}
