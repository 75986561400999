import { Box, Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { urlRoutes } from "./constants";
import LSLogo from "../../assets/StackedLogo.svg";
import LSButton, { buttonVariants } from "../components/buttons/LSButton";
import PageLayout from "../components/layouts/PageLayout";
import LSText, { textVariant } from "../components/text/LSText";

const welcomeText = "Welcome to LinkStep";

export default function Splash() {
  const navigate = useNavigate();

  const handleLoginPress = () => {
    navigate(urlRoutes.login);
  };

  const handleRegisterPress = () => {
    navigate(urlRoutes.register);
  };

  return (
    <PageLayout center>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          minHeight: "85vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            width: "350px",
          }}
        >
          <img src={LSLogo} alt="LinkStep Logo" />
          <LSText
            text={welcomeText}
            variant={textVariant.h1}
            customStyles={{ textAlign: "center", marginBottom: "24px" }}
          />
          <LSButton
            text="Login"
            onPress={handleLoginPress}
            variant={buttonVariants.primary}
          />
          <LSButton
            text="Register"
            onPress={handleRegisterPress}
            variant={buttonVariants.secondary}
          />
        </Paper>
      </Box>
    </PageLayout>
  );
}
