import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Add,
  DeleteForever,
} from "@mui/icons-material";
import { Box, Typography, IconButton, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import AddFileModal from "./modals/AddFileModal";
import DeleteFileModal from "./modals/DeleteFileModal";
import LSText, { textVariant } from "./text/LSText";
import {
  BASE_URL,
  DELETE_FILE,
  GET_SUBMISSION_FILES,
} from "../../shared/apiUrls";
import {
  apiDeleteAuth,
  apiGetAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { urlRoutes } from "../pages/constants";

export default function AttachmentsBox({ tasks }) {
  const [searchParams] = useSearchParams();
  const reportID = searchParams.get("report");
  const [open, setOpen] = useState(true);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteFileName, setDeleteFileName] = useState("");
  const [deleteFileID, setDeleteFileID] = useState("");
  const [files, setFiles] = useState([]);

  const getSubmissionAttachments = async () => {
    const response = await apiGetAuth(`${GET_SUBMISSION_FILES}/${reportID}`);

    return response || [];
  };

  const handleSetFiles = async () => {
    const _fileArrs = tasks?.map((task) => {
      if (task.type === 1 && task.taskFormSubmissionUUID) {
        return [
          {
            filePath: `${urlRoutes.submittedFormView}?report=${task.taskFormSubmissionUUID}`,
            fileName: task.formName,
            type: task.type,
          },
        ];
      }
      return task.savedFiles.map((f) => ({
        ...f,
        filePath: `${BASE_URL}${f.filePath}`,
      }));
    });
    const _fileArr = [];

    const attachments = await getSubmissionAttachments();
    const _attachments = attachments.map((f) => ({
      ...f,
      filePath: `${BASE_URL}${f.filePath}`,
    }));

    _fileArrs.push(_attachments);
    _fileArrs.forEach((arr) => {
      arr.forEach((file) => {
        _fileArr.push(file);
      });
    });
    const fileArr = _fileArr.sort((a, b) => {
      if (a.fileName < b.fileName) {
        return -1;
      }
      return 1;
    });

    setFiles(fileArr);
  };

  const handleAddFile = (_files) => {
    setOpenAddModal(false);

    const filesArr = _files.map((f) => ({
      ...f,
      filePath: `${BASE_URL}${f.filePath}`,
    }));

    const tempArr = [...files, ...filesArr].sort((a, b) => {
      if (a.fileName < b.fileName) {
        return -1;
      }
      return 1;
    });

    setFiles(tempArr);
  };

  const handleDeleteFile = async () => {
    const response = await apiDeleteAuth(`${DELETE_FILE}/${deleteFileID}`);
    setOpenDeleteModal(false);

    if (response === "Success") {
      const fileIdx = files.findIndex((c) => c.uuid === deleteFileID);
      const tempFilesArr = [...files];
      tempFilesArr.splice(fileIdx, 1);
      setFiles(tempFilesArr);
    }
  };

  useEffect(() => {
    handleSetFiles();
  }, [tasks]);

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 2,
        width: "100%",
        padding: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: open ? 1 : 0,
          paddingBottom: open ? 1 : 0,
        }}
      >
        <Typography variant="h6" sx={{ lineHeight: "40px" }}>
          {`Attachments (${files?.length > 0 ? files?.length : 0})`}
        </Typography>
        <Box>
          <IconButton onClick={() => setOpenAddModal(true)}>
            <Add />
          </IconButton>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open}>
        <Box
          sx={{
            maxHeight: "250px",
            overflow: "scroll",
            display: "flex",
            flexDirection: "column",
            paddingTop: 1,
          }}
        >
          {files.map((file) => (
            <Box key={file.filePath}>
              {file.type === 1 ? (
                <LSText
                  text={file.fileName}
                  path={file.filePath}
                  variant={textVariant.H5}
                  blank
                  customLinkStyle={{
                    marginLeft: 0,
                    textDecoration: "none",
                  }}
                />
              ) : (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <a
                    href={file.filePath}
                    target="_blank"
                    download
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "black", flex: 1 }}
                  >
                    {file.fileName}
                  </a>
                  <IconButton
                    onClick={() => {
                      setDeleteFileName(file.fileName);
                      setDeleteFileID(file.uuid);
                      setOpenDeleteModal(true);
                    }}
                    sx={{ marginTop: "-4px", marginRight: "4px", padding: 0 }}
                  >
                    <DeleteForever fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Collapse>
      <AddFileModal
        modalVisible={openAddModal}
        setModalVisible={setOpenAddModal}
        callBack={handleAddFile}
      />
      <DeleteFileModal
        modalVisible={openDeleteModal}
        setModalVisible={setOpenDeleteModal}
        confirmDelete={handleDeleteFile}
        fileName={deleteFileName}
      />
    </Box>
  );
}

AttachmentsBox.propTypes = {
  tasks: PropTypes.array.isRequired,
};

AttachmentsBox.defaultProps = {};
