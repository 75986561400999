import React from "react";

import LSText, { textVariant } from "./text/LSText";
import { fontColorDark } from "../../theme/colors";
import { unauthorizedText } from "../pages/constants";

export default function UnauthorizedAccess() {
  return (
    <LSText
      variant={textVariant.h2}
      text={unauthorizedText}
      color={fontColorDark}
      customStyles={{ marginTop: "32px" }}
    />
  );
}
