import { FontAwesome } from "@expo/vector-icons";
import { Button, Box } from "@mui/material";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { fontColorDark, green } from "../../../theme/colors";
import UserCard from "../../components/cards/UserCard";
import PageLayout from "../../components/layouts/PageLayout";
import AddUserModal from "../../components/modals/AddUserModal";
import UserSettingsModal from "../../components/modals/UserSettingsModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import useLocations from "../../hooks/useLocations";
import usePositions from "../../hooks/usePositions";
import useUsers from "../../hooks/useUsers";
import { pageAppTypes } from "../constants";

const pageTitle = "Users";

export default function UsersSettings() {
  const permissions = useSelector((state) => state.permissions);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [userSettingModalVisible, setUserSettingModalVisible] = useState(false);
  const { locations } = useLocations();
  const { positions } = usePositions();

  const {
    users,
    loading,
    editUser,
    setEditUser,
    handleEditUserLocation,
    handleSaveEditUserChanges,
    setUsers,
  } = useUsers(locations);
  const handleAddNewUser = () => {
    setAddModalVisible(true);
  };

  const handleShowUserProfile = (user) => {
    setEditUser(user);
    setUserSettingModalVisible(true);
  };

  return (
    <PageLayout pageAppType={pageAppTypes.users}>
      <View style={styles.headerContainerStyles}>
        <LSText
          variant={textVariant.h1}
          text={pageTitle}
          color={fontColorDark}
        />
        {permissions.canAddUsers && (
          <Button
            sx={{
              borderRadius: "50%",
              padding: 2,
              height: "60px",
              width: "40px",
              backgroundColor: green[400],
              "&:hover": {
                backgroundColor: green[100],
                color: "#3c52b2",
              },
            }}
            onClick={handleAddNewUser}
          >
            <FontAwesome name="plus" size={25} color={fontColorDark} />
          </Button>
        )}
      </View>
      {loading ? (
        <LSSpinner />
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginTop: 3,
          }}
        >
          <LSText
            variant={textVariant.h6}
            text="Active Users"
            color={fontColorDark}
          />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "16px",
              marginX: -1,
            }}
          >
            {users?.map(
              (user) =>
                (user.active || user.active === null) && (
                  <UserCard
                    handleShowUserProfile={handleShowUserProfile}
                    user={user}
                  />
                )
            )}
          </Box>
          {users.findIndex((c) => c.active === false) >= 0 && (
            <>
              <Box sx={{ marginY: 4, marginX: -2, border: 2 }} />
              <LSText
                variant={textVariant.h6}
                text="Inactive Users"
                color={fontColorDark}
              />
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "16px",
                  marginX: -1,
                }}
              >
                {users?.map(
                  (user) =>
                    user.active === false && (
                      <UserCard
                        handleShowUserProfile={handleShowUserProfile}
                        user={user}
                      />
                    )
                )}
              </Box>
            </>
          )}
        </Box>
      )}
      <AddUserModal
        setUsers={setUsers}
        addModalVisible={addModalVisible}
        setAddModalVisible={setAddModalVisible}
        positions={positions}
        locations={locations}
      />
      <UserSettingsModal
        visible={userSettingModalVisible}
        setVisible={setUserSettingModalVisible}
        user={editUser}
        setUser={setEditUser}
        handleEditUserLocation={handleEditUserLocation}
        locations={locations}
        handleSaveEditUserChanges={handleSaveEditUserChanges}
        positions={positions}
        setUsers={setUsers}
        users={users}
      />
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 8 },
  submissionContainer: {
    borderBottomColor: fontColorDark,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});
