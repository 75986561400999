import { Box, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import LSModal from "./LSModal";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSText, { textVariant } from "../text/LSText";

const maxTextCharCount = 500;

export default function AddSubmissionNoteModal({
  modalVisible,
  setModalVisible,
  newNote,
  handleNoteEdit,
  handleSaveNote,
}) {
  return (
    <LSModal visible={modalVisible} setVisible={setModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            marginBottom: 32,
          }}
        >
          <Box
            sx={{
              borderBottom: 2,
              marginX: -2,
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <LSText
              text="Add Note"
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16, textAlign: "center" }}
            />
          </Box>
          <Box>
            <TextField
              id="filled-textarea"
              label="Note Text"
              rows={6}
              multiline
              variant="filled"
              name="text"
              value={newNote.text}
              onChange={handleNoteEdit}
              sx={{ width: "100%", marginTop: 3 }}
              helperText={`${newNote.text.length} / ${maxTextCharCount}`}
              FormHelperTextProps={{
                sx: { textAlign: "right" },
              }}
              error={newNote.text.length > maxTextCharCount}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LSButton
                onPress={() => setModalVisible(false)}
                text="Cancel"
                variant={buttonVariants.secondary}
                customStyles={{ marginRight: 16, flex: 1 }}
              />
              <LSButton
                onPress={handleSaveNote}
                variant={buttonVariants.primary}
                text="Save"
                disabled={newNote.text.length > maxTextCharCount}
                customStyles={{ flex: 1 }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </LSModal>
  );
}

AddSubmissionNoteModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  newNote: PropTypes.object.isRequired,
  handleSaveNote: PropTypes.func.isRequired,
  handleNoteEdit: PropTypes.func.isRequired,
};

AddSubmissionNoteModal.defaultProps = {};
