import { FontAwesome } from "@expo/vector-icons";
import { Paper, Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  apiLockForm,
  apiPublishForm,
  apiUnlockForm,
  apiUnpublishForm,
} from "../../../shared/components/Sliders/utils";
import { fontColorDark } from "../../../theme/colors";
import { urlRoutes } from "../../pages/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import LSText, { textVariant } from "../text/LSText";

const successResponse = "Success";

export default function FormCard({
  name,
  form,
  questionCount,
  handleOnPressPublish,
  handleOnLockPublish,
  handleOnEditPress,
}) {
  const { userUUID } = useSelector((state) => state.permissions);
  const isAdminIdx = form?.formAdmins?.findIndex(
    (c) => c.userUUID === userUUID
  );
  const admin = form?.formAdmins[isAdminIdx];

  const navigate = useNavigate();

  const { isPublished, isLocked, uuid } = form;
  const handleOnPressPreview = () => {
    navigate(`${urlRoutes.form}?form=${uuid}`);
  };

  const handleOnPressMap = () => {
    navigate(`${urlRoutes.incidentsReportsMapping}?form=${uuid}`);
  };

  const handleOnPressPublishForm = async () => {
    const response = await apiPublishForm(uuid);
    if (response === successResponse) {
      handleOnPressPublish();
    }
  };

  const handleOnPressUnpublish = async () => {
    const response = await apiUnpublishForm(uuid);
    if (response === successResponse) {
      handleOnPressPublish();
    }
  };

  const handleOnPressLockForm = async () => {
    const response = await apiLockForm(uuid);
    if (response === successResponse) {
      handleOnLockPublish();
    }
  };

  const handleOnPressUnlock = async () => {
    const response = await apiUnlockForm(uuid);
    if (response === successResponse) {
      handleOnLockPublish();
    }
  };

  return (
    <Paper
      elevation={2}
      sx={{
        width: "380px",
        margin: "8px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <LSText
          text={name}
          variant={textVariant.h3}
          customStyles={{ textAlign: "center" }}
        />
      </Box>
      <View style={styles.buttonContainerStyles}>
        <LSButton
          text={isPublished ? "Go To Form" : "Preview"}
          variant={buttonVariants.primary}
          customStyles={{ flex: 1 }}
          onPress={handleOnPressPreview}
        />
        {admin && (
          <>
            <LSButton
              text="Map"
              variant={buttonVariants.secondary}
              customStyles={{ flex: 1, marginHorizontal: 12 }}
              onPress={handleOnPressMap}
            />
            <LSButton
              text={isPublished ? "Unpublish" : "Publish"}
              variant={buttonVariants.tertiary}
              customStyles={{}}
              onPress={
                isPublished ? handleOnPressUnpublish : handleOnPressPublishForm
              }
            />
          </>
        )}
      </View>
      <View style={styles.editFormButtonContainerStyles}>
        <View style={{ flexDirection: "row" }}>
          {(admin || true) && (
            <Pressable onPress={() => handleOnEditPress(form)}>
              <FontAwesome name="gear" size={20} color={fontColorDark} />
            </Pressable>
          )}
          {admin && !admin.isViewOnly && (
            <Pressable
              style={{ marginLeft: 8 }}
              onPress={isLocked ? handleOnPressUnlock : handleOnPressLockForm}
            >
              <FontAwesome
                name={isLocked ? "lock" : "unlock"}
                size={20}
                color={fontColorDark}
              />
            </Pressable>
          )}
        </View>
        <View style={styles.editFormButtonContainerRightStyles}>
          <LSText
            text={`${questionCount} Questions`}
            variant={textVariant.button}
            customStyles={{ marginRight: 8 }}
          />
        </View>
      </View>
    </Paper>
  );
}

const styles = StyleSheet.create({
  buttonContainerStyles: {
    dixplay: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  editFormButtonContainerStyles: {
    marginTop: 16,
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  editFormButtonContainerRightStyles: {
    flexDirection: "row",
    alignItems: "center",
  },
});

FormCard.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  questionCount: PropTypes.number.isRequired,
  handleOnPressPublish: PropTypes.func.isRequired,
  handleOnLockPublish: PropTypes.func.isRequired,
  handleOnEditPress: PropTypes.func.isRequired,
};
