import { Box, Typography, Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export default function ReportInfoBox({ report, handleStatusChange, admin }) {
  console.log("This is the report", { report, admin });
  return (
    <Box>
      <Box
        sx={{
          border: 1,
          borderRadius: 1,
          padding: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <b>Status: </b>
          </Box>
          <Select
            id="demo-simple-select"
            value={report.status}
            onChange={handleStatusChange}
            sx={{
              height: "24px",
              fontSize: 12,
              display: "flex",
              flex: 1,
            }}
            disabled={Boolean(admin && admin?.isViewOnly)}
          >
            {report.form.statuses.map((status) => (
              <MenuItem value={status.name} sx={{ marginLeft: 0 }}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <b>Date: </b>
          </Box>
          {new Date(
            `${new Date(report?.submitDate).toLocaleString()} UTC`
          ).toLocaleString()}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <b>User: </b>
          </Box>
          {`${report?.user?.firstName} ${report?.user?.lastName}`}
        </Typography>
      </Box>
    </Box>
  );
}

ReportInfoBox.propTypes = {
  report: PropTypes.object.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
};
