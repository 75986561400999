import { FontAwesome } from "@expo/vector-icons";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { blue } from "../../../theme/colors";
import { getFormattedDate } from "../../utils/utils";

export default function FileViewer({ file, minDisplay }) {
  const [preview, setPreview] = useState();
  const fileDate = new Date(file.lastModified);

  const _file = new FileReader();

  _file.onload = () => {
    setPreview(_file.result);
  };

  _file.readAsDataURL(file);

  return minDisplay ? (
    <Box sx={{ border: 1, borderRadius: 1, padding: 1, marginTop: 1 }}>
      <Typography level="h1">{file.name}</Typography>
    </Box>
  ) : (
    <Box sx={{ display: "flex", flexDirection: "row", marginTop: "12px" }}>
      <Box sx={{ width: "80px" }}>
        {file.type === "image/jpeg" || file.type === "image/png" ? (
          <img
            src={preview}
            alt="uploaded file"
            style={{
              maxHeight: "80px",
              maxWidth: "80px",
              borderRadius: "8px",
            }}
          />
        ) : (
          <FontAwesome name="file-text-o" size={80} color={blue[700]} />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "12px",
          flex: 1,
        }}
      >
        <Typography level="h1">
          <b>Name:</b> {file.name}
        </Typography>
        <Typography level="h1">
          <b>File Type:</b> {file.type}
        </Typography>
        <Typography level="h1">
          <b>Last Modified:</b> {getFormattedDate(fileDate)}
        </Typography>
      </Box>
    </Box>
  );
}

FileViewer.propTypes = {
  file: PropTypes.object.isRequired,
  minDisplay: PropTypes.bool,
};

FileViewer.defaultProps = {
  minDisplay: false,
};
