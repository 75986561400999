import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import LSModal from "./LSModal";
import useAttachSubmissionFile from "../../hooks/useAttachSubmissionFile";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import InputFileUpload from "../formInputs/InputFileUpload";
import LSText, { textVariant } from "../text/LSText";

export default function AddFileModal({
  modalVisible,
  setModalVisible,
  callBack,
}) {
  const { object, handleFileUpload, handleSave } =
    useAttachSubmissionFile(callBack);
  return (
    <LSModal visible={modalVisible} setVisible={setModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            marginBottom: 32,
          }}
        >
          <Box
            sx={{
              borderBottom: 2,
              marginX: -2,
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <LSText
              text="Attach Files"
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16, textAlign: "center" }}
            />
          </Box>
          <Box sx={{ width: "100%", marginTop: 2 }}>
            <InputFileUpload
              handleUploadFile={handleFileUpload}
              answer={object}
              minDisplay
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LSButton
                onPress={() => setModalVisible(false)}
                text="Cancel"
                variant={buttonVariants.secondary}
                customStyles={{ marginRight: 16, flex: 1 }}
              />
              <LSButton
                onPress={handleSave}
                text="Save"
                variant={buttonVariants.primary}
                customStyles={{ flex: 1 }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </LSModal>
  );
}

AddFileModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  callBack: PropTypes.func.isRequired,
};

AddFileModal.defaultProps = {};
