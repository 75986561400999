import { KeyboardArrowUp, KeyboardArrowDown, Add } from "@mui/icons-material";
import { Box, Typography, IconButton, Collapse } from "@mui/material";
import React from "react";

import AddSubmissionNoteModal from "./modals/AddSubmissionNote";
import useSubmissionNotes from "../hooks/useSubmissionNotes";
import { getLocalDateTime } from "../utils/utils";

export default function SubmissionNotesBox() {
  const {
    open,
    openModal,
    notes,
    newNote,
    setOpen,
    handleNoteEdit,
    setOpenModal,
    handleSaveNote,
  } = useSubmissionNotes();

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 2,
        width: "100%",
        padding: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: open ? 1 : 0,
          paddingBottom: open ? 1 : 0,
        }}
      >
        <Typography variant="h6" sx={{ lineHeight: "40px" }}>
          {`Notes (${notes?.length > 0 ? notes?.length : 0})`}
        </Typography>
        <Box>
          <IconButton onClick={() => setOpenModal(true)}>
            <Add />
          </IconButton>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open}>
        <Box
          sx={{
            maxHeight: "250px",
            overflow: "scroll",
            display: "flex",
            flexDirection: "column",
            paddingTop: 1,
          }}
        >
          {notes.map((note) => (
            <Box
              key={note.text}
              sx={{
                backgroundColor: "lightGray",
                padding: 2,
                borderRadius: 2,
                marginBottom: 1,
              }}
            >
              <Typography variant="body2">{note.text}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: 1,
                }}
              >
                <Typography variant="body2">{note.submitterName}</Typography>
                <Typography variant="body2">
                  {getLocalDateTime(note.createdAt)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Collapse>
      <AddSubmissionNoteModal
        newNote={newNote}
        modalVisible={openModal}
        setModalVisible={setOpenModal}
        handleNoteEdit={handleNoteEdit}
        handleSaveNote={handleSaveNote}
      />
    </Box>
  );
}

SubmissionNotesBox.propTypes = {};

SubmissionNotesBox.defaultProps = {};
