/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from "@reduxjs/toolkit";

import { defaultUserPermissions } from "../pages/constants";

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState: defaultUserPermissions,
  reducers: {
    updatePermissions: (state, action) => {
      state[action.payload] = !state[action.payload];
    },
    setPermissions: (state, action) => {
      state.userUUID = action.payload.userUUID;
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
  },
});

export const { updatePermissions, setPermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
