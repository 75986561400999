import { useState, useEffect } from "react";

import {
  GET_FORM_DEFAULT_TASKS,
  GET_SUBMISSION_TASKS,
  UPDATE_TASKS_ORDER,
} from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPatchAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useTasks(submissionUUID, formUUID) {
  const [tasks, setTasks] = useState([]);
  const [counter, setCounter] = useState(0);

  const getTasks = async () => {
    if (submissionUUID) {
      const response = await apiGetAuth(
        `${GET_SUBMISSION_TASKS}/${submissionUUID}`
      );
      setTasks(response);
    } else if (formUUID) {
      const response = await apiGetAuth(
        `${GET_FORM_DEFAULT_TASKS}/${formUUID}`
      );
      setTasks(response);
    }
  };

  const handleNewTaskAdd = (task) => {
    const taskIdx = tasks.findIndex((c) => c.uuid === task.uuid);
    if (taskIdx < 0) {
      setTasks((prevState) => [...prevState, task]);
    } else {
      const updatedArr = tasks.map((t) => {
        if (t.uuid === task.uuid) {
          return task;
        }
        return t;
      });
      setTasks(updatedArr);
    }
  };

  const handleRemoveDeletedTask = (task) => {
    const updatedArr = [];

    tasks.forEach((t) => {
      if (t.uuid !== task.uuid) {
        updatedArr.push(t);
      }
    });
    setTasks(updatedArr);
  };

  const handleSaveReorder = async (_tasks) => {
    await apiPatchAuth(UPDATE_TASKS_ORDER, _tasks);
  };

  useEffect(() => {
    getTasks(submissionUUID);
  }, [submissionUUID, counter]);

  return {
    tasks,
    handleNewTaskAdd,
    handleRemoveDeletedTask,
    setTasks,
    handleSaveReorder,
    setCounter,
  };
}
