import { useState, useEffect } from "react";

import { SUBMITTED_FORMS } from "../../shared/apiUrls";
import { apiGetAuth } from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useSubmissions(selectedForm) {
  const [submissions, setSubmissions] = useState([]);
  const [columnQuestions, setColumnQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSubmissions = async () => {
    setLoading(true);
    const response = await apiGetAuth(`${SUBMITTED_FORMS}/${selectedForm}`);
    if (response.summaryDtos) {
      const questions = response.questionDtos;
      const summaries = response.summaryDtos;
      let name = "";
      for (let i = 0; i < questions.length; i += 1) {
        name = questions[i].text.replace(/ /g, "");
        const _name = `${name[0].toLowerCase()}${name.slice(1)}`;
        questions[i].columnFieldName = _name;

        for (let x = 0; x < summaries.length; x += 1) {
          const answerIdx = summaries[x].answers.findIndex(
            (c) => c.questionUUID === questions[i].uuid
          );
          const answer = summaries[x].answers[answerIdx];
          summaries[x] = {
            ...summaries[x],
            [_name]: answer?.text || answer?.selectedDate,
          };
        }
      }
      setColumnQuestions(questions);
      setSubmissions(response.summaryDtos);
    } else {
      setColumnQuestions([]);
      setSubmissions(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    getSubmissions();
  }, [selectedForm]);

  return { submissions, columnQuestions, loading };
}
