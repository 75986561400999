import { useState, useEffect } from "react";

import { GET_MY_FORMS_SUMMARY_SHORT } from "../../shared/apiUrls";
import { apiGetAuth } from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useFormsShort() {
  const [forms, setForms] = useState([]);
  const [loadingShortForm, setLoading] = useState(false);

  const getForms = async () => {
    setLoading(true);
    const response = await apiGetAuth(GET_MY_FORMS_SUMMARY_SHORT);
    setForms(response);
    setLoading(false);
  };

  useEffect(() => {
    getForms();
  }, []);
  return { forms, loadingShortForm };
}
