// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from "uuid";

export const unauthorizedText =
  "Sorry, you don't have authorization to access this page.";

export const pageBreakPointValues = {
  base: 0,
  sm: 390,
  md: 768,
  lg: 1024,
  xl: 1512,
};

export const pageNames = {
  splash: "splash",
  home: "home",
  login: "login",
  register: "register",
};

export const urlRoutes = {
  splash: "/",
  home: "/home",
  login: "/login",
  register: "/register",
  incidentsDashboard: "/incidents-dashboard",
  incidentsForms: "/incidents-forms",
  incidentsReports: "/incidents-reports",
  incidentsReportsMapping: "/incidents-reports-mapping",
  incidentsReportsEditing: "/incidents-reports-editing",
  incidentsReportsPreview: "/incidents-form-preview",
  submittedFormView: "/submitted-report-view",
  form: "/form",
  locationsDashboard: "/locations-dashboard",
  locationsSettings: "/locations-settings",
  usersDashboard: "/users-dashboard",
  usersSettings: "/users-settings",
  setPassword: "/set-password",
  photoGallery: "/photo-gallery",
  receiptTracker: "/receipt-tracker",
  inventoryManagement: "/inventory-management",
  assetManagement: "/asset-management",
  companySettings: "/company-settings",
  companyPositions: "/company-positions",
  termsOfService: "/terms-of-service",
  privacy: "/privacy-policy",
};

export const fontFamilyTypes = {
  fontAwesome: "fontAwesome",
  fontAwesome5: "fontAwesome5",
  foundation: "foundation",
  ionIcons: "ionIcons",
  octIcons: "octIcons",
  materialIcons: "materialIcons",
  materialCommunityIcons: "materialCommunityIcons",
};

export const customContextMenuFunctionCalls = {
  addNewNextQuestionClick: "handleAddNewNextQuestionClick",
  duplicateNextQuestionClick: "handleDuplicateQuestionClick",
  deleteQuestionClick: "handleDeleteQuestionClick",
  editQuestionClick: "handleEditQuestionClick",
  userNotificationsClick: "handleUserNotificationsClick",
};

export const customQuestionContextMenu = [
  {
    text: "Add New Next Question",
    iconName: "plus",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.addNewNextQuestionClick,
  },
  {
    text: "Duplicate Next Existing Question",
    iconName: "duplicate",
    iconFamily: fontFamilyTypes.ionIcons,
    functionCall: customContextMenuFunctionCalls.duplicateNextQuestionClick,
  },
  {
    text: "Edit Question",
    iconName: "edit",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.editQuestionClick,
  },
  {
    text: "Delete Question",
    iconName: "trash",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.deleteQuestionClick,
  },
  {
    text: "Users Notifications",
    iconName: "users",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.userNotificationsClick,
  },
];

export const customAnswerContextMenu = [
  {
    text: "Add Contingent Question",
    iconName: "plus",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.addNewNextQuestionClick,
  },
  {
    text: "Duplicate Existing Sub Question",
    iconName: "duplicate",
    iconFamily: fontFamilyTypes.ionIcons,
    functionCall: customContextMenuFunctionCalls.duplicateNextQuestionClick,
  },
  {
    text: "Users Notifications",
    iconName: "users",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.userNotificationsClick,
  },
];

export const homeApps = [
  {
    id: uuid(),
    title: "Company Settings",
    iconName: "gears",
    baseRoute: urlRoutes.companySettings,
    iconFamily: fontFamilyTypes.fontAwesome,
  },
  {
    id: uuid(),
    title: "Users",
    iconName: "users",
    baseRoute: urlRoutes.usersSettings,
    iconFamily: fontFamilyTypes.fontAwesome,
  },
  {
    id: uuid(),
    title: "Locations",
    iconName: "building",
    baseRoute: urlRoutes.locationsSettings,
    iconFamily: fontFamilyTypes.fontAwesome,
  },
  {
    id: uuid(),
    title: "Incident Tracker",
    iconName: "alert",
    baseRoute: urlRoutes.incidentsReports,
    iconFamily: fontFamilyTypes.foundation,
  },
  {
    id: uuid(),
    title: "Receipt Tracker",
    iconName: "truck",
    baseRoute: urlRoutes.receiptTracker,
    iconFamily: fontFamilyTypes.fontAwesome,
  },
  {
    id: uuid(),
    title: "Photo Gallery",
    iconName: "photo",
    baseRoute: urlRoutes.photoGallery,
    iconFamily: fontFamilyTypes.fontAwesome,
  },
  {
    id: uuid(),
    title: "Inventory Management",
    iconName: "inventory",
    baseRoute: urlRoutes.inventoryManagement,
    iconFamily: fontFamilyTypes.materialIcons,
  },
  {
    id: uuid(),
    title: "Asset Management",
    iconName: "forklift",
    baseRoute: urlRoutes.assetManagement,
    iconFamily: fontFamilyTypes.materialCommunityIcons,
  },
];

export const pageAppTypes = {
  incidentReports: "incidentReports",
  locations: "locations",
  users: "users",
  companySettings: "companySettings",
};

export const pageTypeComponents = {
  [pageAppTypes.incidentReports]: {
    footerButtons: [
      {
        id: uuid(),
        name: "Home",
        iconName: "home",
        urlRoute: urlRoutes.home,
      },
      {
        id: uuid(),
        name: "Dashboard",
        iconName: "dashboard",
        urlRoute: urlRoutes.incidentsDashboard,
      },
      {
        id: uuid(),
        name: "Forms",
        iconName: "file-text-o",
        urlRoute: urlRoutes.incidentsForms,
      },
      {
        id: uuid(),
        name: "Reports",
        iconName: "files-o",
        urlRoute: urlRoutes.incidentsReports,
      },
      {
        id: uuid(),
        name: "Settings",
        iconName: "gear",
        urlRoute: urlRoutes.home,
      },
    ],
  },
  [pageAppTypes.locations]: {
    footerButtons: [
      {
        id: uuid(),
        name: "Home",
        iconName: "home",
        urlRoute: urlRoutes.home,
      },
      {
        id: uuid(),
        name: "Dashboard",
        iconName: "dashboard",
        urlRoute: urlRoutes.locationsDashboard,
      },
      {
        id: uuid(),
        name: "Manage",
        iconName: "users-cog",
        urlRoute: urlRoutes.locationsSettings,
        iconFamily: fontFamilyTypes.fontAwesome5,
      },
    ],
  },
  [pageAppTypes.users]: {
    footerButtons: [
      {
        id: uuid(),
        name: "Home",
        iconName: "home",
        urlRoute: urlRoutes.home,
      },
      {
        id: uuid(),
        name: "Dashboard",
        iconName: "dashboard",
        urlRoute: urlRoutes.usersDashboard,
      },
      {
        id: uuid(),
        name: "Manage",
        iconName: "users-cog",
        urlRoute: urlRoutes.usersSettings,
        iconFamily: fontFamilyTypes.fontAwesome5,
      },
    ],
  },
  [pageAppTypes.companySettings]: {
    footerButtons: [
      {
        id: uuid(),
        name: "Home",
        iconName: "home",
        urlRoute: urlRoutes.home,
      },
    ],
  },
};

export const emptyAnswer = {
  text: "",
  selectedDate: null,
  questionUUID: "",
  answerOptionUUID: "",
  answerOptionIDs: [],
};

export const defaultUserPermissions = {
  // User page
  canAccessUserPage: false,
  canAddUsers: false,
  canEditUsers: false,
  canDeactivateUsers: false,
  // Location page
  canAccessLocationPage: false,
  canAddLocations: false,
  canEditLocations: false,
  // Incidents page
  canAddNewForms: false,
  canAccessIncidentFormsPage: false,
  canSubmitForms: false,
  canViewSubmittedReports: false,
  // Company page
  canAccessCompanyProfilePage: false,
  canEditCompanyData: false,
  // Positions page
  canAccessPositionsPage: false,
  canAddPositions: false,
  canEditPositions: false,
  userUUID: null,
};

export const userErrorsObject = {
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  position: false,
  role: false,
};

export const permissionsList = [
  {
    category: "Company Profile",
    PermissionCategoryID: 1,
    permissions: [
      {
        name: "canAccessCompanyProfilePage",
        permissionName: "View Company Profile Page",
      },
      {
        name: "canEditCompanyData",
        permissionName: "Edit Company Data",
      },
    ],
  },
  {
    category: "Users",
    PermissionCategoryID: 2,
    permissions: [
      {
        name: "canAccessUserPage",
        permissionName: "View Users Page",
      },
      {
        name: "canAddUsers",
        permissionName: "Add Users",
      },
      {
        name: "canEditUsers",
        permissionName: "Edit Users",
      },
      {
        name: "canDeactivateUsers",
        permissionName: "Deactivate Users",
      },
    ],
  },
  {
    category: "Positions",
    PermissionCategoryID: 3,
    permissions: [
      {
        name: "canAccessPositionsPage",
        permissionName: "View Positions Page",
      },
      {
        name: "canAddPositions",
        permissionName: "Add Positions",
      },
      {
        name: "canEditPositions",
        permissionName: "Edit Positions",
      },
    ],
  },
  {
    category: "Locations",
    PermissionCategoryID: 4,
    permissions: [
      {
        name: "canAccessLocationPage",
        permissionName: "View Location Page",
      },
      {
        name: "canAddLocations",
        permissionName: "Add Location",
      },
      {
        name: "canEditLocations",
        permissionName: "Edit Location",
      },
    ],
  },
  {
    category: "Incident Reports",
    PermissionCategoryID: 5,
    permissions: [
      {
        name: "canAccessIncidentFormsPage",
        permissionName: "View Incident Forms Page",
      },
      {
        name: "canAddNewForms",
        permissionName: "Add Forms",
      },
      {
        name: "canSubmitForms",
        permissionName: "Submit Forms",
      },
      {
        name: "canViewSubmittedReports",
        permissionName: "View Submitted Reports",
      },
    ],
  },
];

export const drawerWidthSmall = 180;
export const drawerWidthLarge = 240;
