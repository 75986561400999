import { FontAwesome } from "@expo/vector-icons";
import { Close } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, IconButton, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  BASE_URL,
  DOWNLOAD_PDF_REPORT,
  SUBMITTED_FORM,
  UPDATE_SUBMISSION_STATUS,
  DELETE_SUBMISSION,
} from "../../../shared/apiUrls";
import { hasTextAnswer } from "../../../shared/hoc/pagesWeb/incidentReporter/constants";
import {
  apiGetAuth,
  apiPostAuth,
  apiDeleteAuth,
} from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorDark, blue } from "../../../theme/colors";
import ActionItemsBox from "../../components/ActionItemsBox";
import AttachmentsBox from "../../components/AttachmentsBox";
import CompleteTaskButton from "../../components/CompleteTaskButton";
import ProgressBar from "../../components/ProgressBar";
import ReportInfoBox from "../../components/ReportInfoBox";
import SubmissionNotesBox from "../../components/SubmissionNotesBox";
import UnauthorizedAccess from "../../components/UnauthorizedAccess";
import LSButton, { buttonVariants } from "../../components/buttons/LSButton";
import Card, { cardVariant } from "../../components/cards/Card";
import PageLayout from "../../components/layouts/PageLayout";
import DeleteSubmissionModal from "../../components/modals/DeleteSubmissionModal";
import LSModal from "../../components/modals/LSModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import useTasks from "../../hooks/useTasks";
import { pageAppTypes, urlRoutes } from "../constants";
import { getFormattedDate, noAnswerProvidedText } from "../utils";

const pageTitle = "Incident Report";

const imageFileTypes = ["png", "jpeg"];

export default function SubmittedFormView() {
  const [searchParams] = useSearchParams();
  const reportID = searchParams.get("report");
  const [loading, setLoading] = useState(true);
  const [changeStatus, setChangeStatus] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [report, setReport] = useState({});
  const navigate = useNavigate();
  const {
    tasks,
    handleNewTaskAdd,
    handleRemoveDeletedTask,
    setTasks,
    handleSaveReorder,
    setCounter,
  } = useTasks(reportID);

  const { userUUID } = useSelector((state) => state.permissions);
  const isAdminIdx = report?.form?.formAdmins?.findIndex(
    (c) => c.userUUID === userUUID
  );
  const admin = report?.form?.formAdmins?.[isAdminIdx];

  const getForm = async () => {
    setLoading(true);
    const response = await apiGetAuth(`${SUBMITTED_FORM}/${reportID}`);
    setReport(response);
    setLoading(false);
  };

  const handleStatusChange = (e) => {
    setChangeStatus(e.target.value);
  };

  const handleUpdateSubmissionStatus = async () => {
    const response = await apiPostAuth(
      `${UPDATE_SUBMISSION_STATUS}/${changeStatus}/${report.uuid}`,
      {}
    );
    if (response === "Success") {
      setReport((prevState) => ({
        ...prevState,
        status: changeStatus,
      }));
      setChangeStatus(null);
    }
  };

  const handleCompleteForm = () => {
    setReport((prevState) => ({
      ...prevState,
      status: "Complete",
    }));
  };

  const getAnswerTextSimple = (a) => {
    if (hasTextAnswer.includes(a.question.typeCode)) {
      return (
        <LSText
          variant={textVariant.h6}
          text={a.text || noAnswerProvidedText}
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      );
    }
    if (a.answerOptionUUID) {
      return (
        <LSText
          variant={textVariant.h6}
          text={a.answerOption.text}
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      );
    }
    if (a.selectedDate) {
      return (
        <LSText
          variant={textVariant.h6}
          text={getFormattedDate(new Date(a.selectedDate))}
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      );
    }
    if (a.answerOptionAnswers?.length > 0) {
      let answerString = "";
      a.answerOptionAnswers.forEach((aoa, idx) => {
        answerString += `${idx + 1}, ${aoa.answerOption.text}\n`;
      });
      return (
        <LSText
          variant={textVariant.h6}
          text={answerString}
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      );
    }
    if (a.formSendUsers?.length > 0) {
      return a.formSendUsers?.map((fsu) => (
        <LSText
          variant={textVariant.h6}
          text={fsu.email}
          path={
            fsu.formSubmitted
              ? `/submitted-report-view?report=${fsu.formSubmitUUID}`
              : null
          }
          color={fontColorDark}
          customStyles={styles.customTextStyles}
        />
      ));
    }
    return (
      <LSText
        variant={textVariant.h6}
        text={a.savedFiles?.length > 0 ? "" : noAnswerProvidedText}
        color={fontColorDark}
        customStyles={styles.customTextStyles}
      />
    );
  };

  const summaryQuestionAnswer = (a, idx) => (
    <View key={`summary-sub-${a.uuid}`} style={styles.questionBorderStyles}>
      <LSText
        variant={textVariant.h5}
        text={`${idx + 1}: ${a.question.requiresAnswer ? "*" : ""}${
          a.question.text
        }`}
        color={fontColorDark}
        customStyles={{ marginBottom: 8 }}
      />
      {getAnswerTextSimple(a)}
      {a.savedFiles?.length > 0
        ? a.savedFiles?.map((file) => {
            const fileType = file.filePath.split(".")[1];
            return imageFileTypes.includes(fileType) ? (
              <a
                key={file.filePath}
                href={`${BASE_URL}${file.filePath}`}
                target="_blank"
                download
                rel="noreferrer"
              >
                <img
                  src={`${BASE_URL}${file.filePath}`}
                  alt="uploaded file"
                  style={{
                    maxHeight: "80px",
                    maxWidth: "80px",
                    borderRadius: "8px",
                    marginTop: "12px",
                  }}
                />
              </a>
            ) : (
              <Box key={file.filePath} sx={{ marginTop: "12px" }}>
                <a
                  href={`${BASE_URL}${file.filePath}`}
                  target="_blank"
                  download
                  rel="noreferrer"
                >
                  <FontAwesome name="file-text-o" size={80} color={blue[700]} />
                </a>
              </Box>
            );
          })
        : ""}
    </View>
  );

  const handleDelete = async () => {
    console.log("deleting", reportID);
    const response = await apiDeleteAuth(`${DELETE_SUBMISSION}/${reportID}`);

    if (response.uuid) {
      setShowDeleteModal(false);
      navigate(urlRoutes.incidentsReports);
    }
  };

  useEffect(() => {
    getForm();
  }, [reportID]);

  return (
    <PageLayout
      pageAppType={pageAppTypes.incidentReports}
      doubleCol={Boolean(admin)}
      topComponents={
        tasks.length > 0 &&
        admin && [<ProgressBar steps={tasks} report={report} />]
      }
      sideComponents={
        report.uuid && [
          <ReportInfoBox
            report={report}
            handleStatusChange={handleStatusChange}
            admin={admin}
          />,
          <CompleteTaskButton
            tasks={tasks}
            setCounter={setCounter}
            handleCompleteForm={handleCompleteForm}
          />,
          <ActionItemsBox
            tasks={tasks}
            formSubmit={report}
            handleNewTaskAdd={handleNewTaskAdd}
            handleRemoveDeletedTask={handleRemoveDeletedTask}
            setTasks={setTasks}
            handleSaveReorder={handleSaveReorder}
            admin={admin}
          />,
          <Box sx={{ marginTop: 2 }} />,
          <SubmissionNotesBox />,
          <Box sx={{ marginTop: 2 }} />,
          <AttachmentsBox tasks={tasks} />,
          ...(admin
            ? [
                <Button
                  onClick={() => setShowDeleteModal(true)}
                  variant="contained"
                  color="error"
                  sx={{ marginTop: 2 }}
                >
                  Delete
                </Button>,
              ]
            : []),
        ]
      }
    >
      <LSText variant={textVariant.h1} text={pageTitle} color={fontColorDark} />
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : !admin ? (
        <UnauthorizedAccess />
      ) : (
        <View style={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LSText
              variant={textVariant.h3}
              text={report.form.name}
              color={fontColorDark}
            />
            <a
              href={`${DOWNLOAD_PDF_REPORT}/${reportID}`}
              download={`${report.form.name}-${report.user.lastName}.pdf`}
            >
              <PictureAsPdfIcon style={{ color: fontColorDark }} />
            </a>
          </Box>
          <ScrollView style={{ marginTop: 24 }}>
            {report.answers?.map((answer, idx) =>
              summaryQuestionAnswer(answer, idx)
            )}
          </ScrollView>
        </View>
      )}
      <DeleteSubmissionModal
        setModalVisible={setShowDeleteModal}
        modalVisible={showDeleteModal}
        confirmDelete={handleDelete}
      />
      <LSModal visible={changeStatus} setVisible={setChangeStatus}>
        <Card
          variant={cardVariant.primary}
          customStyles={{ width: 310, maxWidth: "100%" }}
        >
          <Box sx={{ width: "100%", marginBottom: "0px" }}>
            <Box
              sx={{
                borderBottom: 2,
                marginX: -2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                flex: 1,
              }}
            >
              <Box sx={{ width: "60px" }} />
              <LSText
                text="Update Status"
                variant={textVariant.h2}
                customStyles={{
                  marginBottom: 16,
                  textAlign: "center",
                  flex: 1,
                }}
              />
              <Box sx={{ width: "60px" }}>
                <IconButton
                  onClick={() => setChangeStatus(false)}
                  sx={{ marginTop: "-2px" }}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <LSText
                variant={textVariant.h6}
                text={`Confirm change status to "${changeStatus}"`}
                color={fontColorDark}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LSButton
                text="Cancel"
                variant={buttonVariants.secondary}
                customStyles={{ flex: 1 }}
                onPress={() => setChangeStatus(false)}
              />
              <LSButton
                text="Confirm"
                variant={buttonVariants.primary}
                customStyles={{ flex: 1, marginLeft: 12 }}
                onPress={handleUpdateSubmissionStatus}
              />
            </Box>
          </Box>
        </Card>
      </LSModal>
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyles: {
    marginTop: 32,
  },
  questionBorderStyles: {
    borderBottomColor: fontColorDark,
    borderBottomWidth: 1,
    marginBottom: 16,
    paddingBottom: 16,
  },
  customTextStyles: {
    marginBottom: 8,
    marginLeft: 24,
    fontWeight: "normal",
  },
});
