import { MenuItem, Checkbox, ListItemText, Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

export default function ModalMenuItem({
  key,
  value,
  onClick,
  checked,
  text,
  tooltip,
}) {
  const [show, setShow] = useState(false);
  return (
    <Box
      sx={{ position: "relative", overflow: "visible" }}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <MenuItem key={key} value={value} sx={{ padding: 0 }} onClick={onClick}>
        <Checkbox checked={checked} />
        <ListItemText primary={text} />
      </MenuItem>
      {tooltip ? (
        <Box
          sx={{
            paddingX: 2,
            borderRadius: 1,
            opacity: 0.8,
            position: "absolute",
            top: "-50px",
            backgroundColor: "black",
            width: "260px",
            display: show ? "block" : "none",
          }}
        >
          <p
            style={{
              marginTop: "6px",
              marginBottom: "6px",
              lineHeight: "16px",
              fontSize: "14px",
              color: "white",
            }}
          >
            {tooltip}
          </p>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

ModalMenuItem.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

ModalMenuItem.defaultProps = {
  tooltip: null,
};
