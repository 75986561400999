import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, Text } from "react-native";
import { Link } from "react-router-dom";

import { neutral, yellow } from "../../../theme/colors";

export default function LSText({
  variant,
  text,
  path,
  color,
  customStyles,
  callback,
  customLinkStyle,
  blank,
}) {
  return path ? (
    <Link
      style={{ color, marginLeft: "24px", ...customLinkStyle }}
      to={path}
      target={blank ? "_blank" : "_self"}
      onClick={callback}
    >
      {text}
    </Link>
  ) : (
    <Text style={{ ...style[variant], ...customStyles, color }}>{text}</Text>
  );
}

export const textVariant = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  text: "text",
  navButtonText: "navButtonText",
  caption: "caption",
  overline: "overline",
  error: "error",
  errorCenter: "errorCenter",
  button: "button",
  secondaryButton: "secondaryButton",
  tertiaryButton: "tertiaryButton",
  modalButton: "modalButton",
};

const style = StyleSheet.create({
  h1: {
    fontSize: 30,
    fontWeight: 800,
    marginBottom: 16,
  },
  h2: {
    fontSize: 26,
    marginBottom: 12,
    fontWeight: 700,
  },
  h3: {
    fontSize: 22,
    fontWeight: 600,
  },
  h4: {
    fontSize: 20,
    fontWeight: 600,
  },
  h5: {
    fontSize: 18,
    fontWeight: 600,
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
  },
  navButtonText: {
    fontSize: 10,
    marginBottom: 0,
  },
  caption: {
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: 600,
    marginBottom: 0,
  },
  overline: {
    fontSize: 12,
    marginBottom: 0,
  },
  error: {
    color: "red",
    fontSize: 12,
    fontWeight: 800,
    marginBottom: 0,
  },
  errorCenter: {
    color: "red",
    fontSize: 12,
    fontWeight: 800,
    marginBottom: 0,
    textAlign: "center",
  },
  button: {
    color: neutral[300],
    fontWeight: 700,
    marginBottom: 0,
    fontSize: 12,
  },
  secondaryButton: {
    color: neutral[100],
    fontWeight: 700,
    marginBottom: 0,
    fontSize: 12,
  },
  tertiaryButton: {
    color: yellow[400],
    fontWeight: 700,
    marginBottom: 0,
    fontSize: 12,
  },
  modalButton: {
    color: neutral[200],
    fontWeight: 700,
    marginBottom: 0,
  },
});

LSText.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string.isRequired,
  path: PropTypes.string,
  color: PropTypes.string,
  customStyles: PropTypes.object,
  callback: PropTypes.func,
  customLinkStyle: PropTypes.object,
  blank: PropTypes.bool,
};

LSText.defaultProps = {
  text: "",
  color: "black",
  path: null,
  customStyles: {},
  customLinkStyle: {},
  callback: () => {},
  blank: false,
};
