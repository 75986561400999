import {
  Paper,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { urlRoutes } from "./constants";
import LSLogo from "../../assets/StackedLogo.svg";
import LSButton, { buttonVariants } from "../components/buttons/LSButton";
import LSInput from "../components/formInputs/LSInput";
import PageLayout from "../components/layouts/PageLayout";
import LSText, { textVariant } from "../components/text/LSText";
import useRegistration from "../hooks/useRegistration";

const loginText = "Register";
const inputContainerStyles = {
  display: "flex",
  width: 300,
  flexDirection: "row",
  paddingLeft: 12,
};

export default function Register() {
  const {
    user,
    userErrors,
    registrationPhase,
    setUser,
    handlePressNext,
    handlePressBack,
    handleCheckBoxClick,
  } = useRegistration();

  const registrationPhases = () => {
    if (registrationPhase === 0) {
      return (
        <Box>
          <Box style={inputContainerStyles}>
            <LSInput
              placeholder="First Name"
              name="firstName"
              object={user}
              errorObj={userErrors}
              setter={setUser}
              customStyles={{ width: 280 }}
            />
          </Box>
          <Box style={inputContainerStyles}>
            <LSInput
              placeholder="Last Name"
              name="lastName"
              object={user}
              errorObj={userErrors}
              setter={setUser}
              customStyles={{ width: 280 }}
            />
          </Box>
          <Box style={inputContainerStyles}>
            <LSInput
              placeholder="Phone Number"
              name="phone"
              object={user}
              errorObj={userErrors}
              setter={setUser}
              customStyles={{ width: 280 }}
            />
          </Box>
          <Box style={inputContainerStyles}>
            <LSInput
              placeholder="Email"
              name="email"
              object={user}
              errorObj={userErrors}
              setter={setUser}
              customStyles={{ width: 280 }}
            />
          </Box>
          <Box style={inputContainerStyles}>
            <LSInput
              placeholder="Password"
              name="password"
              object={user}
              errorObj={userErrors}
              setter={setUser}
              password
              customStyles={{ width: 280 }}
            />
          </Box>
          <Box style={inputContainerStyles}>
            <LSInput
              placeholder="Confirm Password"
              name="passwordConfirm"
              object={user}
              errorObj={userErrors}
              setter={setUser}
              password
              customStyles={{ width: 280 }}
            />
          </Box>
        </Box>
      );
    }
    if (registrationPhase === 1) {
      return (
        <Box>
          <Box style={inputContainerStyles}>
            <LSInput
              placeholder="Company Name"
              name="companyName"
              object={user}
              errorObj={userErrors}
              setter={setUser}
              customStyles={{ width: 280 }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              marginTop: 3,
              marginX: 1.5,
              color: !userErrors.terms && !userErrors.policy ? "black" : "red",
            }}
          >
            I have read and agree to the terms of service and privacy policy.
          </Typography>
          <Box style={inputContainerStyles}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.terms}
                  name="terms"
                  onClick={handleCheckBoxClick}
                />
              }
              label={
                <Link target="_blank" to={urlRoutes.termsOfService}>
                  Terms of Service
                </Link>
              }
            />
          </Box>
          <Box style={inputContainerStyles}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.privacy}
                  name="privacy"
                  onClick={handleCheckBoxClick}
                />
              }
              label={
                <Link target="_blank" to={urlRoutes.privacy}>
                  Privacy Policy
                </Link>
              }
            />
          </Box>
        </Box>
      );
    }
    return "Out of range";
  };

  return (
    <PageLayout center>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          minHeight: "85vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            width: "330px",
          }}
        >
          <Box
            sx={{
              marginBottom: "px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={LSLogo}
                style={{
                  height: "120px",
                  width: "190px",
                }}
                alt="LinkStep Logo"
              />
            </Box>
            <LSText
              text={loginText}
              variant={textVariant.h4}
              customStyles={{ textAlign: "center" }}
            />
          </Box>
          {registrationPhases()}
          <Box
            style={{
              display: "flex",
              width: 300,
              paddingLeft: 12,
              paddingRight: 12,
              marginBottom: 8,
              flexDirection: "row",
            }}
          >
            {registrationPhase > 0 && (
              <LSButton
                text="Back"
                onPress={handlePressBack}
                variant={buttonVariants.secondary}
                customStyles={{ flex: 1, marginRight: "8px" }}
              />
            )}
            <LSButton
              text={registrationPhase === 1 ? "Register" : "Next"}
              onPress={handlePressNext}
              customStyles={{ flex: 1 }}
            />
          </Box>
        </Paper>
      </Box>
    </PageLayout>
  );
}
