import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ADD_SUBMISSION_FILES } from "../../shared/apiUrls";
import { apiPostAuth } from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useAttachSubmissionFile(callBack) {
  const [searchParams] = useSearchParams();
  const reportID = searchParams.get("report");

  const [object, setObject] = useState({ files: [] });

  const handleFileUpload = (files) => {
    setObject({ files });
  };

  const handleSave = async () => {
    const _files = [];
    for (let x = 0; x < object.files.length; x += 1) {
      const reader = new FileReader();

      // eslint-disable-next-line no-await-in-loop, no-loop-func
      const fileContent = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(object.files[x]);
      });

      _files.push(`${fileContent},${object.files[x].name}`);
    }
    // object.files = _files;

    const response = await apiPostAuth(
      `${ADD_SUBMISSION_FILES}/${reportID}`,
      _files
    );

    callBack(response);
  };

  return { object, handleFileUpload, handleSave };
}
