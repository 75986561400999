import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  ADD_SUBMISSION_NOTE,
  GET_SUBMISSION_NOTES,
} from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPostAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useSubmissionNotes() {
  const [searchParams] = useSearchParams();
  const reportID = searchParams.get("report");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState({ text: "" });

  const handleNoteEdit = (e) => {
    const { name, value } = e.target;

    setNewNote((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveNote = async () => {
    if (newNote.text) {
      const response = await apiPostAuth(ADD_SUBMISSION_NOTE, {
        ...newNote,
        formSubmitUUID: reportID,
      });

      setNotes((prevState) => [response, ...prevState]);
      setOpenModal(false);
    }
  };

  const handleGetNotes = async () => {
    const results = await apiGetAuth(`${GET_SUBMISSION_NOTES}/${reportID}`);
    setNotes(results);
  };

  useEffect(() => {
    handleGetNotes();
  }, [reportID]);

  return {
    open,
    openModal,
    notes,
    newNote,
    handleNoteEdit,
    handleSaveNote,
    setOpen,
    setOpenModal,
  };
}
