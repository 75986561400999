/* eslint-disable no-nested-ternary */
import { FontAwesome } from "@expo/vector-icons";
import { Close } from "@mui/icons-material";
import { Button, Box, IconButton } from "@mui/material";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { fontColorDark, green } from "../../../theme/colors";
import UnauthorizedAccess from "../../components/UnauthorizedAccess";
import LSButton, { buttonVariants } from "../../components/buttons/LSButton";
import Card, { cardVariant } from "../../components/cards/Card";
import LocationCard from "../../components/cards/LocationCard";
import InputFileUpload from "../../components/formInputs/InputFileUpload";
import LSInput, { inputVariants } from "../../components/formInputs/LSInput";
import PageLayout from "../../components/layouts/PageLayout";
import LSModal from "../../components/modals/LSModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import useLocations from "../../hooks/useLocations";
import { pageAppTypes } from "../constants";

const pageTitle = "Manage Locations";

export default function LocationsSettings() {
  const {
    confirmDeactivate,
    locations,
    locationsInactive,
    loading,
    locationErrors,
    newLocation,
    editLocation,
    editLocationErrors,
    addModalVisible,
    setAddModalVisible,
    setNewLocation,
    cancelDeactivate,
    setEditLocation,
    handleGenerateLocationPress,
    handleEditLocationSubmit,
    handleDeactivateLocation,
  } = useLocations();
  const { canAccessLocationPage, canAddLocations, canEditLocations } =
    useSelector((state) => state.permissions);

  const handleLocationClick = (location) => {
    setEditLocation(location);
    setAddModalVisible(true);
  };

  const handleAddNewLocation = () => {
    setAddModalVisible(true);
  };

  const handleProfileSelect = (files) => {
    if (editLocation) {
      setEditLocation((prevState) => ({
        ...prevState,
        files: [files?.[0]],
      }));
    } else {
      setNewLocation((prevState) => ({
        ...prevState,
        files: [files?.[0]],
      }));
    }
  };

  return (
    <PageLayout pageAppType={pageAppTypes.locations}>
      <View style={styles.headerContainerStyles}>
        <LSText
          variant={textVariant.h1}
          text={pageTitle}
          color={fontColorDark}
        />
        {canAddLocations && canAccessLocationPage && (
          <Button
            sx={{
              borderRadius: "50%",
              padding: 2,
              height: "60px",
              width: "40px",
              backgroundColor: green[400],
              "&:hover": {
                backgroundColor: green[100],
                color: "#3c52b2",
              },
            }}
            onClick={handleAddNewLocation}
          >
            <FontAwesome name="plus" size={25} color={fontColorDark} />
          </Button>
        )}
      </View>
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : !canAccessLocationPage ? (
        <UnauthorizedAccess />
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginTop: 3,
          }}
        >
          <LSText
            variant={textVariant.h6}
            text="Active Locations"
            color={fontColorDark}
          />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "16px",
              marginX: -1,
            }}
          >
            {locations?.map((location) => (
              <LocationCard
                location={location}
                handleLocationClick={handleLocationClick}
              />
            ))}
          </Box>
          <Box sx={{ marginY: 4, marginX: -2, border: 2 }} />
          <LSText
            variant={textVariant.h6}
            text="Inactive Locations"
            color={fontColorDark}
          />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "16px",
              marginX: -1,
            }}
          >
            {locationsInactive?.map((location) => (
              <LocationCard
                location={location}
                handleLocationClick={handleLocationClick}
              />
            ))}
          </Box>
        </Box>
      )}

      <LSModal
        visible={addModalVisible}
        setVisible={(val) => {
          setEditLocation(null);
          setAddModalVisible(val);
        }}
      >
        <Card
          variant={cardVariant.primary}
          customStyles={{ width: 310, maxWidth: "100%" }}
        >
          <View style={{ width: "100%", marginBottom: 0 }}>
            <Box
              sx={{
                borderBottom: 2,
                marginX: -2,
                marginBottom: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                flex: 1,
              }}
            >
              <Box sx={{ width: "60px" }} />
              <LSText
                text={editLocation ? "Edit Location" : "Add New Location"}
                variant={textVariant.h2}
                customStyles={{
                  marginBottom: 16,
                  textAlign: "center",
                  flex: 1,
                }}
              />
              <Box sx={{ width: "60px" }}>
                <IconButton
                  onClick={() => setAddModalVisible(0)}
                  sx={{ marginTop: "-2px" }}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
            {!confirmDeactivate ? (
              <>
                <LSInput
                  variant={inputVariants.primary}
                  setter={editLocation ? setEditLocation : setNewLocation}
                  object={editLocation || newLocation}
                  name="name"
                  placeholder="Name"
                  errorObj={editLocation ? editLocationErrors : locationErrors}
                  customStyles={styles.inputStyles}
                  disabled={editLocation && !canEditLocations}
                />
                <LSInput
                  variant={inputVariants.primary}
                  setter={editLocation ? setEditLocation : setNewLocation}
                  object={editLocation || newLocation}
                  name="address"
                  placeholder="Address"
                  errorObj={editLocation ? editLocationErrors : locationErrors}
                  customStyles={styles.inputStyles}
                  disabled={editLocation && !canEditLocations}
                />
                <LSInput
                  variant={inputVariants.primary}
                  setter={editLocation ? setEditLocation : setNewLocation}
                  object={editLocation || newLocation}
                  name="city"
                  placeholder="City"
                  errorObj={editLocation ? editLocationErrors : locationErrors}
                  customStyles={styles.inputStyles}
                  disabled={editLocation && !canEditLocations}
                />
                <LSInput
                  variant={inputVariants.primary}
                  setter={editLocation ? setEditLocation : setNewLocation}
                  object={editLocation || newLocation}
                  name="state"
                  placeholder="State"
                  errorObj={editLocation ? editLocationErrors : locationErrors}
                  customStyles={styles.inputStyles}
                  disabled={editLocation && !canEditLocations}
                />
                <LSInput
                  variant={inputVariants.primary}
                  setter={editLocation ? setEditLocation : setNewLocation}
                  object={editLocation || newLocation}
                  name="zip"
                  placeholder="Zip"
                  errorObj={editLocation ? editLocationErrors : locationErrors}
                  customStyles={styles.inputStyles}
                  disabled={editLocation && !canEditLocations}
                />
                <InputFileUpload
                  handleUploadFile={handleProfileSelect}
                  answer={editLocation || newLocation}
                  disabled={editLocation && !canEditLocations}
                />
              </>
            ) : (
              <LSText
                text="Are you sure you want to delete this location?"
                variant={textVariant.h6}
                customStyles={{ marginBottom: 16, textAlign: "center" }}
              />
            )}
          </View>
          <View style={styles.buttonsContainer}>
            <LSButton
              onPress={
                confirmDeactivate ? cancelDeactivate : handleDeactivateLocation
              }
              text={
                confirmDeactivate
                  ? "Cancel"
                  : editLocation?.isDeleted
                  ? "Reactivate"
                  : "Deactivate"
              }
              variant={
                confirmDeactivate
                  ? buttonVariants.secondary
                  : buttonVariants.error
              }
              customStyles={{ marginRight: 16, flex: 1 }}
              disabled={editLocation && !canEditLocations}
            />
            <LSButton
              onPress={
                confirmDeactivate
                  ? handleDeactivateLocation
                  : editLocation
                  ? handleEditLocationSubmit
                  : handleGenerateLocationPress
              }
              variant={
                confirmDeactivate
                  ? buttonVariants.error
                  : buttonVariants.primary
              }
              text={
                confirmDeactivate
                  ? editLocation?.isDeleted
                    ? "Reactivate"
                    : "Deactivate"
                  : "Save"
              }
              customStyles={{ flex: 1 }}
              disabled={editLocation && !canEditLocations}
            />
          </View>
        </Card>
      </LSModal>
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 0,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 16 },
  submissionContainer: {
    borderBottomColor: fontColorDark,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});
